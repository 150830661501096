import React from "react";
import { Link } from "react-router-dom";

const NavLink: React.FC<{ name: string; to: string; isReleased: boolean }> = ({
  name,
  to,
  isReleased,
}) => {
  if (isReleased) {
    return (
      <Link className="hover:text-Pink drop-shadow-pink cursor-pointer relative z-20" to={to}>
        {name}
      </Link>
    );
  } else {
    return (
      <Link className="hover:text-Pink cursor-pointer group relative z-20" to={to}>
        {name}
        <span className="pointer-events-none absolute -bottom-10 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-DarkBlue px-2 py-1 text-White opacity-0 transition before:absolute before:left-1/2 before:bottom-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100 z-10">
          Coming Soon...
        </span>
      </Link>
    );
  }
};

export default NavLink;

import React from "react";

export default function CopyRight() {
  return (
    <footer>
      <h1 className="relative bottom-0 left-0 right-0 text-Orange text-xs md:text-sm lg:text-xl text-center font-CySemibold font-medium -z-10">
        © 2024 Engineers United
      </h1>
    </footer>
  );
}

import React from "react";
import NavBar from "../component/NavBar/NavBar";
import Background from "../component/Background";
import ShopMerchButton from "../component/ShopMerchButton";
import AboutPageHeroView from "../views/AboutPage/HeroView";
import CopyRight from "../component/CopyRight";

export default function AboutPage() {
  return (
    <Background>
      <ShopMerchButton />
      <NavBar />
      <AboutPageHeroView />
      <CopyRight />
    </Background>
  );
}

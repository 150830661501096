import React from "react";
import tshirt from "../assets/icons/tshirt.png";
import { useNavigate } from "react-router-dom";

export default function ShopMerchButton() {
  let navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate("/shop");
      }}
      className="bg-Yellow hidden right-0 top-28 z-10 fixed p-1 border-Yellow border-2 rounded-l-lg items-center gap-x-2 cursor-pointer hover:scale-110 active:scale-90 active:translate-x-4"
    >
      <p className="text-White font-HiraginoSans font-medium hidden md:block">
        SHOP MERCH
      </p>
      <img className="h-auto w-8" alt="" src={tshirt} />
    </button>
  );
}

import React from "react";
import NavBar from "../component/NavBar/NavBar";
import ShopMerchButton from "../component/ShopMerchButton";
import CopyRight from "../component/CopyRight";
import Hero from "../views/SchedulePage/HeroView";
// import MoreInformation from "../component/MoreInformation";
import Background from "../component/Background";
import SocialMedias from "../component/SocialMedias";

export default function SchedulePage() {
  return (
    <Background>
      <ShopMerchButton />
      <SocialMedias />
      <NavBar />
      <Hero />
      {/*<MoreInformation />*/}
      <CopyRight />
    </Background>
  );
}

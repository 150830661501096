import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import SchedulePage from "./pages/SchedulePage";
import SponsorPage from "./pages/SponsorPage";
// import ComingSoonPage from "./pages/ComingSoonPage";
import ShopPage from "./pages/ShopPage";
import Error404Page from "./pages/Error404Page";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import React from "react";

function App() {
  return (
    <Router>
      <AnimatePresence exitBeforeEnter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/s/:id" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/sponsor" element={<SponsorPage/>} />
          <Route path="/schedule" element={<SchedulePage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="*" element={<Error404Page />} />
          
        </Routes>
      </AnimatePresence> 
    </Router>

  );
}

export default App;

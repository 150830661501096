import React, { forwardRef } from "react";

const Challenges = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div className="md:px-4 lg:px-8 xl:px-16" ref={ref}>
      <div className="text-White my-16 mx-4 md:mx-8 lg:mx-12 xl:mx-18 p-4 md:grid md:grid-cols-1 xl:grid-cols-5 md:auto-rows-auto">
        <div className="flex flex-col col-start-2 col-span-3 text-center">
          <h2 className="text-Lavender  text-4xl mt-4 mb-4 italic font-CySemibold font-semibold">
            The Challenges
          </h2>
          <p className="font-HiraginoSans font-medium mb-4">
            HackUNT 2024 will host a variety of challenges from different
            industries. Participants can choose to work on multiple challenges
            during the event!
          </p>
          <p className="font-HiraginoSans font-medium">
            Challenges will be available on Devpost at noon on Saturday, October
            26th. Stay tuned for updates.
             <a
              href="https://hackunt-2024.devpost.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline text-Neon"
            >
              Click here to view challenges on Devpost.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
});

export default Challenges;

import React, { useState, useEffect } from "react";
import { motion, useScroll } from "framer-motion";
import { ReactComponent as Discord } from "../assets/socials/discord.svg";
import { ReactComponent as Instagram } from "../assets/socials/instagram.svg";
import { ReactComponent as LinkedIn } from "../assets/socials/linkedin.svg";
import { ReactComponent as Facebook } from "../assets/socials/facebook.svg";
import { ReactComponent as Twitter } from "../assets/socials/twitter.svg";

const noFollow = (e: Event) => e.preventDefault();

interface SocialIconProps {
  href: string;
  hidden: boolean;
  children: React.ReactNode;
}

const SocialIcon: React.FC<SocialIconProps> = ({ href, hidden, children }) => {
  return (
    <a
      className={`${
        hidden ? "cursor-default pointer-events-none" : "cursor-pointer"
      } fill-Yellow rounded-full h-12 w-12`}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <div className="">{children}</div>
    </a>
  );
};

export default function SocialMedias() {
  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState<boolean>(false);
  let prev: number = scrollY.get();

  function update(latest: number) {
    if (latest < prev) {
      setHidden(false);
    } else if (latest > prev) {
      setHidden(true);
    }
    prev = latest;
  }

  useEffect(() => {
    return scrollY.onChange((latest) => update(latest));
  });

  useEffect(() => {
    let links = document.querySelectorAll("#SocialMedias a");

    if (hidden) {
      links.forEach((link) => {
        link.addEventListener("click", noFollow);
      });
    } else {
      links.forEach((link) => {
        link.removeEventListener("click", noFollow);
      });
    }
  }, [hidden]);

  const ScrollVariants = {
    ScrollVisible: { opacity: 1, y: 0 },
    ScrollHidden: { opacity: 0, y: -25 },
  };

  return (
    <motion.div
      id="SocialMedias"
      variants={ScrollVariants}
      animate={hidden ? "ScrollHidden" : "ScrollVisible"}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
      className="grid grid-cols-1 grid-rows-4 top-[55%] md:top-96 z-10 fixed gap-2 md:gap-4 lg:gap-8 left-2 md:left-4 lg:left-8 xl:left-16"
    >
      <SocialIcon href="https://discord.gg/CsfRZyvRDT" hidden={hidden}>
        <Discord
          className="h-12 w-12"
          alt="Link to HackUNT's Discord"
        />
      </SocialIcon>
      <SocialIcon
        href="https://www.instagram.com/officialhackunt/"
        hidden={hidden}
      >
        <Instagram
            className="h-12 w-12"
            alt="Link to HackUNT's Instagram"
        />
      </SocialIcon>
      <SocialIcon
        href="https://www.facebook.com/OfficialHackUNT/"
        hidden={hidden}
      >
        <Facebook
            className="h-12 w-12 translate-x-[3px]"
            alt="Link to HackUNT's Facebook"
        />
      </SocialIcon>
      <SocialIcon
        href="https://www.linkedin.com/company/hackunt/"
        hidden={hidden}
      >
        <LinkedIn
            className="h-12 w-12 -translate-x-px"
            alt="Link to HackUNT's LinkedIn"
        />
      </SocialIcon>
      <SocialIcon
          href="https://x.com/officialhackunt"
          hidden={hidden}
      >
        <Twitter
            className="h-12 w-12 -translate-x-px"
            alt="Link to HackUNT's Twitter"
        />
      </SocialIcon>
    </motion.div>
  );
}

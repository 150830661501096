import React from "react";
import NavBar from "../component/NavBar/NavBar";
import ShopMerchButton from "../component/ShopMerchButton";
import ShopPageHeroView from "../views/ShopPage/HeroView";
import CopyRight from "../component/CopyRight";
import Background from "../component/Background";

export default function ShopPage() {
  return (
    <Background>
      <ShopMerchButton />
      <NavBar />
      <ShopPageHeroView />
      <CopyRight />
    </Background>
  );
}

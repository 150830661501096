import React from "react";
import { motion, SVGMotionProps } from "framer-motion";

type PathProps = SVGMotionProps<SVGPathElement>;

const Path: React.FC<PathProps> = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="rgb(250 238 212)"
    strokeLinecap="round"
    {...props}
  />
);

export default Path;

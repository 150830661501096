import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "../../hooks/useDimensions";
import HackuntLogo from "../../assets/logos/hackuntlogo.svg";
import NavLink from "./NavLink";
import MenuToggle from "./MenuToggle";
import MenuItem from "./MenuItem";

export default function NavBar() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 100% 6rem)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: "circle(5rem at calc(100% + 6rem) 6rem)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const navigation = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const navigate = useNavigate();
  function goHome() {
    navigate("/");
    if (isOpen) {
      toggleOpen();
    }
  }


  const scrollToTopAndClose = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/schedule");
    if (isOpen) {
      toggleOpen();
    }
  };

  const links = [
    {
      name: "Register",
      path: "/s/register",
      isReleased: true,
    },
    {
      name: "About",
      path: "/about",
      isReleased: true,
    },
    {
      name: "Sponsor",
      path: "/s/sponsor",
      isReleased: true,
    },
    {
      name: "FAQ",
      path: "/s/faq",
      isReleased: true,
    },
    {
      name: "Challenges",
      path: "/s/challenges",
      isReleased: true,
    },
    {
      name: "Schedule",
      path: "/schedule",
      isReleased: false,
    },
  ];

  return (
    <>
      <div
        className="fixed cursor-pointer z-10 top-4 md:top-8 lg:left-8 xl:left-16"
        onClick={goHome}
      >
        <div
          className={`${
            isOpen ? "hidden" : "absolute"
          } top-0 w-44 h-full blur-lg -z-10`}
        ></div>
        <img
          alt="Return to Home"
          className="h-12 w-44 drop-shadow-xl"
          src={HackuntLogo}
        />
      </div>
      <nav className="py-8 sticky top-0 bg-gradient-to-b from-DarkBlue to-Pink/20 w-full flex justify-center items-center">
        <div className="relative">
          <div className="absolute top-0 w-full h-full -z-10 blur-lg hidden md:block" />
          <ul className="hidden md:flex text-White md:gap-x-6 md:text-md lg:gap-x-12 lg:text-lg xl:gap-x-16 2xl:gap-x-24 2xl:text-xl list-none py-2 font-outfit font-semibold">
            {links.map((link) => {
              return (
                <li key={link.name}>
                  {link.name === "Schedule" ? (
                    <span
                      className="hover:text-Pink cursor-pointer relative z-20"
                      onClick={scrollToTopAndClose}
                    >
                      {link.name}
                    </span>
                  ) : (
                    <NavLink
                      name={link.name}
                      to={link.path}
                      isReleased={link.isReleased}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
      <motion.div
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        className="fixed md:hidden top-20 right-0 left-0 h-0"
      >
        <motion.div
          className="absolute top-0 -translate-y-32 right-0 left-0 h-[130vh] bg-DarkBlue z-10"
          variants={sidebar}
        />
        <motion.ul
          className={`${
            isOpen ? "" : "hidden"
          } text-center w-full text-White text-2xl sm:text-4xl list-none py-12 p-8 gap-y-4 z-20 h-screen`}
          variants={navigation}
        >
          {links.map((link) => {
            return (
              <MenuItem
                key={link.name}
                onClick={() => {
                  toggleOpen();
                }}
              >
                <NavLink
                  name={link.name}
                  to={link.path}
                  isReleased={link.isReleased}
                />
              </MenuItem>
            );
          })}
        </motion.ul>
        <MenuToggle toggle={() => toggleOpen()} />
      </motion.div>
    </>
  );
}

import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Hero from "../views/HomePage/HeroView";
import NavBar from "../component/NavBar/NavBar";
import Registration from "../views/HomePage/RegisterView";
// import MoreInformation from "../component/MoreInformation";
import Challenges from "../views/HomePage/ChallengesView";
import ShopMerchButton from "../component/ShopMerchButton";
import CopyRight from "../component/CopyRight";
import SocialMedias from "../component/SocialMedias";
import Background from "../component/Background";
import FAQ from "../views/HomePage/FAQView";
import Stats from "../views/HomePage/StatsView";
import MLHbanner from "../views/HomePage/MLHbanner"
import Sponsor from "../views/HomePage/SponsorView"

export default function HomePage() {
  const RegistrationRef = useRef<HTMLDivElement>(null);
  const ChallengesRef = useRef<HTMLDivElement>(null);
  const FAQRef = useRef<HTMLDivElement>(null);
  const SponsorRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  console.log({ id });

  const scrollableLinks = new Map([
    ["register", RegistrationRef],
    ["sponsor", SponsorRef],
    ["faq", FAQRef],
    ["challenges", ChallengesRef],
  ]);

  useEffect(() => {
    if (!id) return;
    window.scrollTo({
      top: (scrollableLinks.get(id)?.current?.offsetTop ?? 0) - 100,
      behavior: "smooth",
    });
  });

  return (
    
    <Background>
      <MLHbanner/>
      <ShopMerchButton />
      <SocialMedias />
      <NavBar />
      <Hero /> 
      <Registration ref={RegistrationRef} />
      <Stats />
      <Challenges ref={ChallengesRef} />
      <FAQ ref={FAQRef} />
      <Sponsor ref={SponsorRef} />
      {/*<MoreInformation />*/}
      <CopyRight />
    </Background>
  );
}

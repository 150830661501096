import React from "react";

export default function Ghost() {
  return (
    <div className="-z-[1]">
      <img
        className="w-16 h-16 2xl:w-48 2xl:h-48 animate-fade"
        alt=""
        src="/images/Ghost_Yellow.svg"
      ></img>
    </div>
  );
}

import React from "react";
import NavBar from "../component/NavBar/NavBar";
import Background from "../component/Background";
import ShopMerchButton from "../component/ShopMerchButton";
// import AboutPageHeroView from "../views/AboutPage/HeroView";
import CopyRight from "../component/CopyRight";
// import ComingSoonHeroView from "../views/ComingSoonPage/HeroView"
import SponsorPageHeroView from "../views/HomePage/SponsorView"



export default function SponsorPage() {
  return (
    <Background>
      <ShopMerchButton />
      <NavBar />
      {/*<AboutPageHeroView />*/}
      {/*  <ComingSoonHeroView />*/}
      <SponsorPageHeroView />
      <CopyRight />
    </Background>
  );
}

import React, { forwardRef } from "react";
import AsteriskOpened from "../../assets/graphics/Asterisk_Blue.svg";
import AsteriskClosed from "../../assets/graphics/Asterisk_White.svg";
import "./FAQView.css";

const FAQ = forwardRef<HTMLDivElement>((_, ref) => {
  const FAQs: Array<{ question: string; answer: string }> = [
    {
      question: "What is a hackathon?",
      answer:
        "HackUNT is a weekend-long event where hundreds of students from around the country come together to work on excellent new software and/or hardware projects.",
    },
    {
      question: "When is HackUNT?",
      answer: "HackUNT is October 26-27th, 2024",
    },
    {
      question: "How much does it cost?",
      answer:
        "Admission is free and includes mentors, workshops, $wag, resources, and a memorable experience!",
    },
    {
      question: "It's my first hackathon. What do I do?",
      answer:
        "That's okay! We'll be hosting beginner workshops where you can start getting your hands dirty with all things hackathon. There will also be beginner prizes available!",
    },
    {
      question: "Where's the schedule?",
      answer:
        "Check out the Schedule tab or go-to 'https://unthackathon.com/schedule'",
    },
    {
      question: "Can we have Teams?",
      answer:
        "Your team can have up to 4 people! We will collect the teams at check-in!",
    },
    {
      question: "Can I start early?",
      answer:
        "Sadly, no, that would make it super unfair! But you can research and brainstorm ideas beforehand!",
    },
    {
      question: "Do I have to be a programmer?",
      answer:
        "No way! We will love to have you if you have an idea and a free weekend! Feel free to meet with sponsors, check out our other events, and interact with our mentors!",
    },
    {
      question: "Do I have to stay the whole time?",
      answer:
        "Nope! Feel free to go home and get some sleep. Just be back before judging!",
    },
  ];

  return (
    <div className="md:px-4 lg:px-8 xl:px-16" ref={ref}>
      <div className="text-White my-16 mx-4 md:mx-8 lg:mx-12 xl:mx-18 p-4 2xl:grid 2xl:grid-cols-4 2xl:auto-rows-auto">
        <div className="2xl:col-start-2 2xl:col-end-4">
          <h2 className="text-Lavender text-4xl mt-4 mb-4 italic font-CySemibold font-semibold text-center">
            FAQ
          </h2>
          <ul
            className="md:h-64 overflow-y-scroll my-2 md:my-4 lg:my-8 xl:my-16"
            id="FAQ"
            style={{
              scrollbarColor: "#EAF408 transparent",
              scrollbarWidth: "thin",
              direction: "rtl",
            }}
          >
            {FAQs.map((item, index) => {
              return (
                <li key={index} className="py-2" style={{ direction: "ltr" }}>
                  <details className="group/summary">
                    <summary className="italic font-semibold text-lg md:text-xl group-open/summary:text-Blue grid grid-cols-[auto_auto] grid-rows-1 gap-x-2 items-start pb-1 md:pb-2 lg:pb-3 xl:pb-4">
                      <div className="justify-self-start text-left">
                        {item.question}
                      </div>
                      <img
                        src={AsteriskOpened}
                        alt=""
                        className="group-open/summary:block hidden justify-self-end"
                      />
                      <img
                        src={AsteriskClosed}
                        alt=""
                        className="group-open/summary:hidden block justify-self-end"
                      />
                    </summary>
                    <p className="pb-1 md:pb-2 lg:pb-3 xl:pb-4 border-b-[1px] border-White text-md font-normal text-left text-White">
                      {item.answer}
                    </p>
                  </details>
                </li>
              );
            })}
            {/* A JSX comment2 */}
            <li className="py-2" style={{ direction: "ltr" }}>
              <details className="group/summary">
                <summary className="italic font-semibold text-lg md:text-xl group-open/summary:text-Blue grid grid-cols-[auto_auto] grid-rows-1">
                  <div className="justify-self-start pb-1 md:pb-2 lg:pb-3 xl:pb-4 text-left">
                    What is the Code of Conduct?
                  </div>
                  <img
                    src={AsteriskOpened}
                    alt=""
                    className="group-open/summary:block hidden justify-self-end"
                  />
                  <img
                    src={AsteriskClosed}
                    alt=""
                    className="group-open/summary:hidden block justify-self-end"
                  />
                </summary>
                <p className="pb-1 md:pb-2 lg:pb-3 xl:pb-4 border-b-[1px] border-White text-md font-normal text-left">
                  We welcome all students to a safe environment to explore and
                  create! All attending must follow the [{" "}
                  <a
                    href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf"
                    className="text-Lavender"
                    rel="noreferrer"
                    target="_blank"
                  >
                    MLH Code of Conduct!
                  </a>{" "}
                  ]
                </p>
              </details>
            </li>
            <li className="py-2" style={{ direction: "ltr" }}>
              <details className="group/summary">
                <summary className="italic font-semibold text-lg md:text-xl group-open/summary:text-Blue grid grid-cols-[auto_auto] grid-rows-1">
                  <div className="justify-self-start pb-1 md:pb-2 lg:pb-3 xl:pb-4 text-left">
                    Still have questions?
                  </div>
                  <img
                    src={AsteriskOpened}
                    alt=""
                    className="group-open/summary:block hidden justify-self-end"
                  />
                  <img
                    src={AsteriskClosed}
                    alt=""
                    className="group-open/summary:hidden block justify-self-end"
                  />
                </summary>
                <p className="pb-1 md:pb-2 lg:pb-3 xl:pb-4 border-b-[1px] border-White text-md font-normal text-left">
                  Contact us! hackuntfaq@gmail.com
                </p>
              </details>
            </li>
          </ul>
          <p className="font-HiraginoSans font-medium">
            Email{" "}
            <a
              className="text-Neon text-decoration-line: underline"
              href="mailto:hackuntfaq@gmail.com"
            >
              hackuntfaq@gmail.com
            </a>{" "}
            for any additional questions.
          </p>
        </div>
      </div>
    </div>
  );
});

export default FAQ;

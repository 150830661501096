import React from "react";
import ScheduleBlock, {
  ScheduleBlockProps,
} from "../../component/ScheduleBlock";
// event categories are core, event, workshop, meal
// id's should start at 0
//setting lengthMinutes to 0 makes it a single line instead of a block
const saturdaySchedule: ScheduleBlockProps[] = [
  {
    id: 0,
    startTime: new Date(2024, 10, 26, 9, 30),
    lengthMinutes: 90,
    title: "Check In",
    category: "core",
  },
  {
    id: 1,
    startTime: new Date(2024, 10, 26, 10, 0),
    lengthMinutes: 60,
    title: "Sponsor Career Fair",
    category: "event",
  },
  {
    id: 2,
    startTime: new Date(2024, 10, 26, 11, 0),
    lengthMinutes: 60,
    title: "Opening Ceremony - Room B185",
    category: "event",
  },
  {
    id: 3,
    startTime: new Date(2024, 10, 26, 12, 0),
    lengthMinutes: 0,
    title: "Hacking Starts",
    category: "core",
  },
  {
    id: 4,
    startTime: new Date(2024, 10, 26, 12, 0),
    lengthMinutes: 60,
    title: "Lunch",
    category: "meal",
    mealColor: "rainbow",
  },
  {
    id: 5,
    startTime: new Date(2024, 10, 26, 13, 0),
    lengthMinutes: 60,
    title: "Cybersecurity - Protecting the Vulnerable with CBRE - Room B155",
    category: "workshop",
  },
/*  {
    id: 6,
    startTime: new Date(2024, 10, 26, 14, 0),
    lengthMinutes: 60,
    title: "Understanding Agentic Flow  with Andrew Hooker",
    category: "workshop",
  },*/
  {
    id: 7,
    startTime: new Date(2024,10,26,15,0),
    lengthMinutes:60,
    title: "Pumpkin Painting - Room B192",
    category: "event"
  },
  {
    id: 8,
    startTime: new Date(2024, 10, 26, 16, 0),
    lengthMinutes: 60,
    title: "Figma Workshop with MLH - Room B155",
    category: "workshop",
  },
  {
    id: 9,
    startTime: new Date(2024, 10, 26, 17, 0),
    lengthMinutes: 60,
    title: "Cloud Deployment with RESPEC - Room B190",
    category: "workshop",
  },
  {
    id: 10,
    startTime: new Date(2024, 10, 26, 19, 0),
    lengthMinutes: 60,
    title: "Dinner",
    category: "meal",
    mealColor: "rainbow",
  },
  {
    id: 11,
    startTime: new Date(2024, 10, 26, 19, 30),
    lengthMinutes: 60,
    title: "Boo Bingo - Room B190",
    category: "event",
  },
  {
    id: 12,
    startTime: new Date(2024, 10, 26, 20, 0),
    lengthMinutes: 60,
    title: "GitHub Copilot with MLH - Room B155",
    category: "workshop",
  },
  {
    id: 13,
    startTime: new Date(2024, 10, 26, 22, 0),
    lengthMinutes: 90,
    title: "Late Night Movie - Room B185",
    category: "event",
  },
  {
    id: 14,
    startTime: new Date(2024, 10, 26, 23, 0),
    lengthMinutes: 90,
    title: "Late Night Snack",
    category: "meal",
    mealColor: "rainbow",
  },
];
const sundaySchedule: ScheduleBlockProps[] = [
  {
    id: 0,
    startTime: new Date(2023, 10, 27, 9, 0),
    lengthMinutes: 60,
    title: "Breakfast",
    category: "meal",
    mealColor: "rainbow",
  },
  {
    id: 1,
    startTime: new Date(2023, 10, 27, 10, 0),
    lengthMinutes: 60,
    title: "Open API with Fidelity Investments - Room B190",
    category: "workshop",
  },
  {
    id: 2,
    startTime: new Date(2023, 10, 27, 12, 0),
    lengthMinutes: 0,
    title: "Hacking Ends",
    category: "core",
  },
  {
    id: 3,
    startTime: new Date(2023, 10, 27, 12, 0),
    lengthMinutes: 60,
    title: "Lunch",
    category: "meal",
    mealColor: "rainbow",
  },
  {
    id: 4,
    startTime: new Date(2023, 10, 27, 13, 0),
    lengthMinutes: 90,
    title: "Judging",
    category: "core",
  },
  {
    id: 5,
    startTime: new Date(2023, 10, 27, 14, 30),
    lengthMinutes: 30,
    title: "Debrief",
    category: "core",
  },
  {
    id: 6,
    startTime: new Date(2023, 10, 27, 15, 0),
    lengthMinutes: 60,
    title: "Closing Ceremony - Room B185",
    category: "core",
  },
];

export default function Hero() {
  return (
    <header className="text-White">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-5xl sm:text-6xl md:text-8xl lg:text-10xl md:mt-10 pb-4 font-CySemibold uppercase">
          Event Schedule
        </h2>
        <p className="text-xs md:text-sm lg:text-xl p-4 md:mx-8 lg:mx-16 2xl:mx-32 md:mt-14 md:mb-6 lg:mb-12 md:w-2/3 xl:w-2/5 text-center font-CySemibold font-light">
          For any general inquiries about HackUNT or your participation, please
          send your email to{" "}
          <a
            className="text-Yellow text-decoration-line: underline"
            href="mailto:hackuntfaq@gmail.com"
          >
            hackuntfaq@gmail.com
          </a>
        </p>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap space-between gap-8 font-CySemibold px-1 md:px-16 xl:px-36">
        <div className="grid gap-4 w-full h-min">
          <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-misc lg:mb-10 text-Lavender md:text-left font-CySemibold text-center w-full">
            Saturday, October 26th
          </h2>
          {saturdaySchedule.map((item) => (
            <ScheduleBlock key={item.id} {...item} />
          ))}
        </div>
        <div className="grid gap-4 w-full h-min">
          <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-misc lg:mb-10 text-Lavender md:text-left font-CySemibold font-medium text-center w-full">
            Sunday, October 27th
          </h2>
          {sundaySchedule.map((item) => (
            <ScheduleBlock key={item.id} {...item} />
          ))}
        </div>
      </div>
    </header>
  );
}

import React from "react";

export default function Hero() {
  return (
    <div className="w-full 2xl:grid 2xl:grid-cols-3 2xl:auto-rows-auto">
      <div className="col-start-2 flex flex-col items-center pt-32 p-4 md:mx-8 lg:mx-16 xl:mx-32 2xl:mx-0 font-CySemibold font-medium gap-y-8 md:gap-y-18 text-White text-xl text-center">
        <h2 className="text-5xl sm:text-6xl md:text-8xl lg:text-10xl pb-4 font-CySemibold font-semibold">
          Shop Merch
        </h2>
        <div className="">Coming soon...</div>
      </div>
    </div>
  );
}

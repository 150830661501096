import React from "react";
import Background from "../component/Background";
import Hero from "../views/Error404Page/HeroView";
import SocialMedias from "../component/SocialMedias";
import CopyRight from "../component/CopyRight";
import NavBar from "../component/NavBar/NavBar";

export default function Error404Page() {
  return (
    <Background>
      <NavBar />
      <SocialMedias />
      <Hero />
      <CopyRight />
    </Background>
  );
}

import React from "react";

type ScheduleCategory = "meal" | "event" | "workshop" | "core";
type MealColor = "white" | "pink" | "neon" | "orange" | "rainbow";

interface ScheduleProps {
  id?: number;
  startTime: Date;
  title: string;
  category: ScheduleCategory;
}

export interface ScheduleBlockProps extends ScheduleProps {
  lengthMinutes: number;
  mealColor?: MealColor;
  children?: React.ReactNode;
}

interface ScheduleLineProps extends ScheduleProps {}

const BLOCK_LENGTH = 2; // how tall a block should be, in rem

const formatTime = (date: Date): string => {
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0"); // pad minutes to two digits; 3:03 not 3:3
  let amPm = "am";

  if (hours > 12) {
    hours -= 12;
    amPm = "pm";
  }
  if (hours === 12 && minutes === "00") {
    return "Noon";
  }
  if (hours === 12 && minutes !== "00") {
    amPm = "pm";
  }
  if (hours === 0 && minutes === "00") {
    return "Midnight";
  } else if (hours === 0) {
    hours = 12;
  }

  return `${hours}:${minutes} ${amPm}`;
};

const categoryToClassList = (
  category: ScheduleCategory,
  mealColor?: MealColor
): [string, string] => {
  const mealMap: Record<MealColor, string> = {
    white: "bg-White",
    pink: "bg-Pink",
    neon: "bg-Neon",
    orange: "bg-Orange",
    rainbow: "bg-gradient-to-tr from-Pink to-Blue via-Yellow",
  };

  const categoryMap: Record<ScheduleCategory, string> = {
    core: "bg-Orange text-DarkBlue",
    event: "bg-Pink text-DarkBlue",
    workshop: "bg-Blue text-DarkBlue",
    meal: "bg-DarkBlue text-White",
  };

  if (mealColor) {
    return [
      `rounded-md p-3 h-full ${categoryMap[category]}`,
      `rounded-md p-1 ${mealMap[mealColor]}`,
    ];
  }

  return [`rounded-md p-3 h-full ${categoryMap[category]}`, ""];
};

export const ScheduleLine: React.FC<ScheduleLineProps> = ({
  startTime,
  title,
}) => {
  const startTimeFmt = formatTime(startTime);

  return (
    <div className="flex justify-center md:justify-start items-center gap-4 my-4 w-7/10 md:w-full">
      <h3 className="text-xl text-White min-w-min whitespace-nowrap">
        {title}
      </h3>
      <div className="h-0 border-2 border-Pink hide-mobile w-full" />
      <h3 className="text-xl min-w-min text-Blue md:text-White whitespace-nowrap">
        {startTimeFmt}
      </h3>
    </div>
  );
};

const ScheduleBlock: React.FC<ScheduleBlockProps> = ({
  startTime,
  title,
  lengthMinutes,
  children,
  category,
  mealColor,
}) => {
  const blocks = Math.floor(lengthMinutes / 15);
  const customStyle = {
    height: `${Math.min(blocks * BLOCK_LENGTH, 25)}rem`, // cap at 25rem to avoid overcrowding
  };

  const startTimeFmt = formatTime(startTime);

  const endTime = new Date(startTime.getTime() + lengthMinutes * 60000);
  const endTimeFmt = formatTime(endTime);

  if (lengthMinutes === 0)
    return (
      <ScheduleLine startTime={startTime} title={title} category={category} />
    );

  const [baseStyle, borderStyle] = categoryToClassList(category, mealColor);

  return (
    <div className="flex justify-center md:block">
      <div style={customStyle} className={`schedule-block ${borderStyle}`}>
        <div className={baseStyle + " flex justify-between gap-2"}>
          <h3
            className={`${
              baseStyle.includes("bg-DarkBlue") ? "text-White" : "text-DarkBlue"
            } text-lg md:text-xl`}
          >
            {title}
          </h3>
          <div className="text-sm md:text-lg">
            {startTimeFmt} - {endTimeFmt}
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ScheduleBlock;

import React from "react";
import { motion } from "framer-motion";

interface BackgroundProps {
  children: React.ReactNode;
}

const Background: React.FC<BackgroundProps> = ({ children }) => {
  return (
    <div
      className="bg-dots bg-cover bg-top min-h-screen"
      style={{ scrollBehavior: "smooth" }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 1.2 }}
        className="bg-cover bg-top min-h-screen"
      >
        {children}
      </motion.div>
    </div>
  );
};

export default Background;

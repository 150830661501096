import React, { forwardRef } from "react";
import SponsorImages from "../../component/SponsorImages";
import Pumpkin from "../../assets/graphics/Pumpkin.svg";
const Sponsor = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div className="md:px-4 lg:px-8 xl:px-16">
      <div className="my-16 mx-4 md:mx-8 lg:mx-12 xl:mx-18 p-4 2xl:grid 2xl:grid-cols-4 2xl:auto-rows-auto">
        <div className="2xl:col-start-2 2xl:col-end-4">
          <div className="flex flex-row items-center justify-between">
            <img
              className="-z-10 md:self-start w-16 h-auto self-begin pointer-events-none"
              src={Pumpkin}
              alt=""
            />
            <img
              className="-z-10 md:self-start w-16 h-auto self-begin pointer-events-none"
              src={Pumpkin}
              alt=""
            />
            <img
              className="-z-10 md:self-start w-16 h-auto self-begin pointer-events-none"
              src={Pumpkin}
              alt=""
            />
          </div>

          <h2
            className="text-4xl mt-4 mb-4 italic font-HiraginoSans font-semibold text-center"
            ref={ref}
          >
            Sponsors
          </h2>
        </div>
      </div>
      <div className="col-start-2 flex flex-col items-center pt-0 p-4 md:mx-8 lg:mx-16 xl:mx-32 2xl:mx-0 font-HiraginoSans font-medium gap-y-8 md:gap-y-18 text-xl text-center">
        <SponsorImages />
      </div>
    </div>
  );
});
export default Sponsor;

import React from "react";
import { motion } from "framer-motion";

interface MenuItemProps {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLLIElement>;
}

const MenuItem: React.FC<MenuItemProps> = ({ children, onClick }) => {
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <motion.li
      variants={variants}
      className="hover:scale-110 active:scale-90 pb-8 sm:pb-12"
      onClick={onClick}
    >
      {children}
    </motion.li>
  );
};

export default MenuItem;

import React from "react";
import Path from "./Path";

interface MenuToggleProps {
  toggle: React.MouseEventHandler<HTMLButtonElement>;
}

const MenuToggle: React.FC<MenuToggleProps> = ({ toggle }) => (
  <button onClick={toggle} className="top-9 right-2 md:right-4 fixed pl-1.5 pt-1.5 pr-1 pb-1 bg-Orange border-2 border-Orange rounded-lg flex items-center justify-items-center z-20">
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </button>
);

export default MenuToggle;
